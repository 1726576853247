import type {
  ForwardRefExoticComponent,
  FunctionComponent,
  PropsWithoutRef,
  RefAttributes
} from "react";
import type { LinkProps as RouterLinkProps, To } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import type {
  LinkProps as ThemedUILinkProps,
  ThemeUIStyleObject
} from "theme-ui";
import { Link as ThemeUILink } from "theme-ui";

type ForwardRef<T, P> = ForwardRefExoticComponent<
  PropsWithoutRef<P> & RefAttributes<T>
>;

type CombinedProps = ThemedUILinkProps &
  Omit<RouterLinkProps, "to"> & {
    to?: To;
  };

export const linkTheme: Record<string, ThemeUIStyleObject> = {
  primary: {
    fontFamily: "body",
    color: "primary",
    fontWeight: "bold",
    fontSize: "16px",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: "primaryHover"
    }
  },
  button: {
    fontFamily: "body",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    textDecoration: "none",
    borderRadius: "8px",
    backgroundColor: "primary",
    px: 3,
    py: 2,
    cursor: "pointer"
  }
};

const FinalLink: ForwardRef<HTMLAnchorElement, CombinedProps> = ThemeUILink;

const Link: FunctionComponent<CombinedProps> = ({ children, to, ...props }) => {
  return (
    <FinalLink as={to ? RouterLink : undefined} to={to} {...props}>
      {children}
    </FinalLink>
  );
};

Link.defaultProps = { variant: "primary" };

export default Link;
