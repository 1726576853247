import { cloneElement, useCallback, useState } from "react";
import type { ReactElement } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import type { FlexProps } from "theme-ui";
import Box from "toamea/elements/Box";
import Flex from "toamea/elements/Flex";

interface IDropDown extends FlexProps {
  children: ReactElement;
  label: string;
  buttonChild: ReactElement;
}

const DropDown = ({ children, label, buttonChild }: IDropDown) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    const nextState = isOpen ? false : true;
    setIsOpen(nextState);
  }, [isOpen]);

  const outsideClickRef = useOnclickOutside(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  });
  const buttonChildProps = {
    className: "ignore-onclickoutside",
    "aria-label": label,
    "aria-controls": `${label}-dropdown`
  };
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Box variant="secondary" onClick={toggleOpen} sx={{ cursor: "pointer" }}>
        {cloneElement(buttonChild, buttonChildProps)}
      </Box>
      {isOpen && (
        <Box ref={outsideClickRef}>
          {cloneElement(children, {
            toCloseDropdown: toggleOpen
          })}
        </Box>
      )}
    </Flex>
  );
};

export default DropDown;
