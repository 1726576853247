import type { FC } from "react";
import type { ImageProps, ThemeUIStyleObject } from "theme-ui";
import userIcon from "./images/user-icon-225x225.png";
import Image from "toamea/elements/Image";

interface IProfileImage extends ImageProps {
  profileImage: string;
  sx?: ThemeUIStyleObject;
}

const ProfileImage: FC<IProfileImage> = ({ profileImage, sx, ...props }) => (
  <Image
    alt={props.alt || ""}
    {...props}
    sx={{
      mx: "auto",
      width: ["100px", "150px"],
      height: ["100px", "150px"],
      overflow: "hidden",
      borderRadius: ["50px", "75px"],
      border: "white 4px solid",
      ...sx
    }}
    src={profileImage === "default" ? userIcon : profileImage}
    fallbackSrc={userIcon}
  />
);

export default ProfileImage;
