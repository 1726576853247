import { useContext } from "react";
import type { AccountContextProps } from "../context";
import { AccountContext } from "../context";
import type { AccountData } from "../types";

export const useAccount = (): AccountData => {
  const context = useContext(AccountContext) as AccountContextProps;

  if (!context) {
    throw new Error("useUpdateAccount must be used within an AccountProvider");
  }

  const { state } = context;

  return state;
};
