import type { Theme } from "theme-ui";
import { buttonTheme as buttons } from "toamea/elements/Button";
import { linkTheme as links } from "toamea/elements/Link";
import { textTheme as text } from "toamea/elements/Text";
import { sliderTheme as slider } from "toamea/form/Slider";

const makeTheme = <T extends Theme>(t: T) => t;

export const AVAILABLE_MODES = {
  DEFAULT: "default",
  DARK: "dark"
};

export const themeGlobal = `
  input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
    color: -internal-light-dark(black, white) !important;
  }
  Box:-webkit-autofill:focus {
    box-shadow: none;
    font-size: inherit;
  }
`;

const theme = makeTheme({
  config: {
    initialColorModeName: "default",
    useLocalStorage: true,
    useColorSchemeMediaQuery: true
  },
  buttons,
  links,
  text,
  forms: { slider },
  colors: {
    text: "#000000",
    background: "#fff",
    black: "#000000",
    primary: "#074e54",
    primaryHover: "#1F5F65",
    secondary: "#7ab6bc",
    error: "#cc0000",
    warning: "#ff9900",
    success: "#136728",
    muted: "#7ab6bc",
    gray: "#606060",
    lightGray: "#d1d1d1",
    contrastGray: "#595959",
    modes: {
      dark: {
        text: "#DEDEDE",
        primary: "#8C8C8C",
        primaryHover: "#B0B0B0",
        background: "#111212",
        contrastGray: "#292929"
      }
    }
  },
  fonts: {
    text: "Kanit, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    body: "Kanit, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    headings: "Kanit, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    primary: "Kanit, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    secondary: "Kanit, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    monospace: "Menlo, monospace"
  },
  styles: {
    root: {
      // uses the theme values provided above
      fontFamily: "body",
      fontWeight: "normal",
      ":focus-visible": { outline: "dashed 1px" },
      img: { display: "block" },
      svg: { display: "block" }
    }
  }
});

export default theme;
