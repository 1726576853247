import type { FC } from "react";
import { forwardRef } from "react";
import type { TextProps as ThemeTextProps, ThemeUIStyleObject } from "theme-ui";
import { Text as Base } from "theme-ui";

export const textTheme: Record<string, ThemeUIStyleObject> = {
  default: {
    color: "text",
    fontSize: 3
  },
  caps: {
    textTransform: "uppercase",
    letterSpacing: "0.2em"
  },
  heading: {
    fontFamily: "headings",
    color: "primary",
    fontSize: [16, 24]
  },
  buttons: {
    fontFamily: "body",
    fontWeight: "bold",
    fontSize: "16px",
    textDecoration: "none",
    borderRadius: "8px"
  }
};

type Variant = keyof typeof textTheme;

type TextProps = Omit<ThemeTextProps, "variant"> & {
  variant?: Variant;
};

const Text: FC<TextProps> = forwardRef<HTMLDivElement>(({ ...props }, ref) => (
  <Base as="p" ref={ref} {...props} />
));

Text.displayName = "Text";

Text.defaultProps = { variant: "default" };

export default Text;
