import type { ReactNode, FC } from "react";
import { useReducer, useMemo } from "react";
import type { AccountData } from "../types";
import { getAccount, initializeAccount } from "../utilities";
import { AccountContext } from "./AccountContext";
import { AccountReducer } from "./AccountReducer";

type AccountProviderProps = {
  children: ReactNode;
};

export const initialAccount: AccountData = {
  token: "",
  firstName: "",
  lastInitial: "",
  allowAdult: undefined,
  isCreator: undefined,
  creatorPage: undefined,
  profileImage: "",
  version: 0,
  screenName: "",
  isAuthenticated: undefined
};

export const AccountProvider: FC<AccountProviderProps> = (props) => {
  initializeAccount();
  const isAccount = getAccount();

  const [state, dispatch] = useReducer(
    AccountReducer,
    isAccount || initialAccount
  );

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <AccountContext.Provider value={value} {...props} />;
};
