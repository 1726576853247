import type { FC, ReactNode } from "react";
import { useState } from "react";
import type { ColorFormat } from "react-countdown-circle-timer";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { AlertCircle, AlertOctagon, AlertTriangle, X } from "react-feather";
import type {
  AppearanceTypes,
  ToastProps as ReactToastProps
} from "react-toast-notifications";
import type { ThemeUIStyleObject } from "theme-ui";
import { useThemeUI } from "theme-ui";
import { iconSize } from "toamea/constants";
import Box from "toamea/elements/Box";
import Button from "toamea/elements/Button";
import Flex from "toamea/elements/Flex";
import { AVAILABLE_MODES } from "toamea/theme/theme";
import type { Sizes } from "~/types";

type WarningProps = Partial<ReactToastProps> & {
  appearance?: AppearanceTypes;
  size?: Sizes;
  onDismiss?: (id?: string) => void;
  children?: ReactNode;
  isSelfClosing?: boolean;
  sx?: ThemeUIStyleObject;
};

const Warning: FC<WarningProps> = ({
  appearance: type = "info",
  size = "medium",
  autoDismissTimeout,
  autoDismiss,
  onDismiss,
  children,
  isSelfClosing = false,
  sx
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const { theme, colorMode } = useThemeUI();

  const toastConfig = {
    error: {
      role: "alert",
      Icon: AlertOctagon,
      iconColor: theme.colors?.error,
      bgColor: "#FFEBEB"
    },
    info: {
      role: "alert",
      Icon: AlertCircle,
      iconColor: theme.colors?.primary,
      bgColor:
        colorMode === AVAILABLE_MODES.DARK
          ? theme.colors?.background
          : "#FFFFFF"
    },
    success: {
      role: "alert",
      Icon: AlertCircle,
      iconColor: theme.colors?.success,
      bgColor: "#C1ECD5"
    },
    warning: {
      role: "alert",
      Icon: AlertTriangle,
      iconColor: theme.colors?.warning,
      bgColor: "#FFF0DB"
    }
  };

  const { Icon, role, iconColor, bgColor } = toastConfig[type];
  return isOpen ? (
    <Flex
      sx={{
        flexDirection: "row",
        alignContent: "stretch",
        border: "solid 3px",
        borderRadius: 22,
        borderColor: "primary",
        overflow: "hidden",
        mb: [1, 2],
        minHeight: "62px",
        backgroundColor: bgColor,
        ...sx
      }}
    >
      <Flex
        sx={{
          width: 80,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor:
            colorMode === AVAILABLE_MODES.DARK
              ? theme.colors?.background
              : "white"
        }}
      >
        <Icon color={iconColor as string} size={iconSize[size]} />
      </Flex>
      <Flex
        sx={{
          width: "100%",
          alignContent: "center",
          alignItems: "center",
          py: 1
        }}
      >
        {typeof children === "string" ? (
          <Box as="p" role={role} sx={{ lineHeight: "16px" }}>
            {children}
          </Box>
        ) : (
          <Box role={role}>{children}</Box>
        )}
      </Flex>
      {(isSelfClosing || onDismiss) && (
        <Flex
          sx={{
            width: 80,
            alignContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            aria-label="Close Notification"
            variant="icon"
            onClick={(): void => {
              if (onDismiss) {
                onDismiss();
              }
              if (isSelfClosing) {
                setIsOpen(false);
              }
            }}
          >
            {autoDismiss && autoDismissTimeout ? (
              <CountdownCircleTimer
                isPlaying={true}
                duration={autoDismissTimeout / 1000 + 0.15}
                colors={theme.colors?.secondary as ColorFormat}
                size={iconSize[size] + 5}
                strokeWidth={4}
                trailColor={bgColor as ColorFormat}
              >
                {() => (
                  <X
                    color={theme.colors?.primary as string}
                    size={iconSize[size]}
                  />
                )}
              </CountdownCircleTimer>
            ) : (
              <X
                color={theme.colors?.primary as string}
                size={iconSize[size]}
              />
            )}
          </Button>
        </Flex>
      )}
    </Flex>
  ) : null;
};

export default Warning;
