export const initialState = {
  token: "",
  firstName: "",
  lastInitial: "",
  allowAdult: undefined,
  isCreator: undefined,
  profileImage: "",
  version: 0,
  screenName: "",
  isAuthenticated: undefined
};
