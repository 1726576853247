/* eslint-disable @typescript-eslint/restrict-template-expressions */
import kanitItalicEot from "./Kanit/Kanit-Italic.eot";
import kanitItalicTtf from "./Kanit/Kanit-Italic.ttf";
import kanitItalicWoff from "./Kanit/Kanit-Italic.woff";
import kanitItalicWoff2 from "./Kanit/Kanit-Italic.woff2";
import kanitRegularEot from "./Kanit/Kanit-Regular.eot";
import kanitRegularTtf from "./Kanit/Kanit-Regular.ttf";
import kanitRegularWoff from "./Kanit/Kanit-Regular.woff";
import kanitRegularWoff2 from "./Kanit/Kanit-Regular.woff2";
import kanitBoldEot from "./Kanit/Kanit-SemiBold.eot";
import kanitBoldTtf from "./Kanit/Kanit-SemiBold.ttf";
import kanitBoldWoff from "./Kanit/Kanit-SemiBold.woff";
import kanitBoldWoff2 from "./Kanit/Kanit-SemiBold.woff2";
import kanitBoldItalicEot from "./Kanit/Kanit-SemiBoldItalic.eot";
import kanitBoldItalicTtf from "./Kanit/Kanit-SemiBoldItalic.ttf";
import kanitBoldItalicWoff from "./Kanit/Kanit-SemiBoldItalic.woff";
import kanitBoldItalicWoff2 from "./Kanit/Kanit-SemiBoldItalic.woff2";

const families = { kanit: "Kanit" };

const fontDefinitions = `
  @font-face {
    font-family: ${families.kanit};
    src: url(${kanitBoldEot});
    src: url('${kanitBoldEot}#idfix') format('embedded-opentype),
        url(${kanitBoldWoff}) formate('woff'),
        url(${kanitBoldWoff2}) formate('woff2'),
        url(${kanitBoldTtf}) formate('ttf');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: ${families.kanit};
    src: url(${kanitBoldItalicEot});
    src: url('${kanitBoldItalicEot}#idfix') format('embedded-opentype),
        url(${kanitBoldItalicWoff}) formate('woff'),
        url(${kanitBoldItalicWoff2}) formate('woff2'),
        url(${kanitBoldItalicTtf}) formate('ttf');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: ${families.kanit};
    src: url(${kanitRegularEot});
    src: url('${kanitRegularEot}#idfix') format('embedded-opentype),
        url(${kanitRegularWoff}) formate('woff'),
        url(${kanitRegularWoff2}) formate('woff2'),
        url(${kanitRegularTtf}) formate('ttf');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: ${families.kanit};
    src: url(${kanitItalicEot});
    src: url('${kanitItalicEot}#idfix') format('embedded-opentype),
        url(${kanitItalicWoff}) formate('woff'),
        url(${kanitItalicWoff2}) formate('woff2'),
        url(${kanitItalicTtf}) formate('ttf');
    font-weight: normal;
    font-style: italic;
  }
`;

export default fontDefinitions;
