/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ComponentType } from "react";
import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import type { RouteProperties } from "./routeData";
import { maintenanceRoutes, routes } from "./routeData";
// import { useMode, usePermission } from "clients/utilities/hooks/useUtilities";
import Loading from "components/Loading";

export type IsMaintenanceProps = { isMaintenance?: boolean };

const loadComponent = (
  isMaintenance: boolean,
  path?: string,
  Component?: React.FunctionComponent<any>,
  LazyComponent?: React.LazyExoticComponent<ComponentType<any>>
) => {
  if (Component && (path === "" || path === "/")) {
    return <Component isMaintenance={isMaintenance} />;
  }
  if (LazyComponent) {
    return (
      <Suspense fallback={<Loading />}>
        <LazyComponent isMaintenance={isMaintenance} />
      </Suspense>
    );
  }
  if (Component) {
    return <Component isMaintenance={isMaintenance} />;
  }
  return null;
};

const loadLazyRoute = (
  mainRoutes: RouteProperties[],
  parentTitle?: string,
  isMaintenance = false
) =>
  mainRoutes.map(
    ({
      title,
      Component,
      LazyComponent,
      routes: subRoute,
      caseSensitive,
      index,
      path
    }) => {
      let formattedTitle = "";

      if (parentTitle) {
        formattedTitle += `${parentTitle} `;
      }
      if (title) {
        formattedTitle += `${title} `;
      }
      if (title || parentTitle) {
        formattedTitle += "- ";
      }
      formattedTitle += "Toa Games";

      return (
        <Route
          key={`${parentTitle || "toa"}-${title
            .replace(" ", "-")
            .toLowerCase()}`}
          caseSensitive={caseSensitive}
          index={index}
          path={path}
          element={
            <>
              {/* ToDo: fix title so you can added dynamic content in it. */}
              {/* ":subPage > :game > :gameEdit Edit Game", // ToDo: Inject Params in title from useParams */}
              <Helmet title={formattedTitle} />
              {loadComponent(isMaintenance, path, Component, LazyComponent)}
            </>
          }
        >
          {subRoute && subRoute.length > 0
            ? loadLazyRoute(subRoute, title, isMaintenance)
            : null}
        </Route>
      );
    }
  );

const PageRoutes = () => {
  const mode = true; // const { data: mode } = useMode();
  const permission = false; // const { data: permission } = usePermission({ enabled: mode ? true : false });

  return (
    <Routes>
      {loadLazyRoute(
        !permission && mode ? maintenanceRoutes : routes,
        undefined,
        !permission && mode
      )}
    </Routes>
  );
};

export default PageRoutes;
