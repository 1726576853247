import type { FC } from "react";
import type { BoxProps } from "theme-ui";
import { Box } from "theme-ui";

const Container: FC<BoxProps> = ({ children, sx }) => (
  <Box sx={{ maxWidth: "1280px", mx: "auto", ...sx }}>{children}</Box>
);

Container.displayName = "Container";

export default Container;
