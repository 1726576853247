import type { AccountData } from "../types";

// eslint-disable-next-line no-shadow
export enum AccountActions {
  SET_ACCOUNT = "SET_ACCOUNT",
  UPDATE_ACCOUNT = "UPDATE_ACCOUNT",
  UNSET_ACCOUNT = "UNSET_ACCOUNT"
}

interface ISetAccountAction {
  type: AccountActions.SET_ACCOUNT;
  payload: AccountData;
}

interface IUnsetAccountAction {
  type: AccountActions.UNSET_ACCOUNT;
}

interface IUpdateAccountAction {
  type: AccountActions.UPDATE_ACCOUNT;
  payload: Partial<AccountData>;
}

export type AccountActionsTypes =
  | ISetAccountAction
  | IUnsetAccountAction
  | IUpdateAccountAction;
