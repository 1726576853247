import type { Dispatch } from "react";
import { createContext } from "react";
import type { AccountData } from "../types";
import type { AccountActionsTypes } from "./Action";

export interface AccountContextProps {
  state: AccountData;
  dispatch: Dispatch<AccountActionsTypes>;
}

export const AccountContext = createContext<AccountContextProps | unknown>({});
