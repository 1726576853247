import type { FC } from "react";
import { forwardRef } from "react";
import type { HeadingProps } from "theme-ui";
import { Heading as Base } from "theme-ui";

const Heading: FC<HeadingProps> = forwardRef((props, ref) => (
  <Base ref={ref} {...props} />
));

Heading.displayName = "Heading";

Heading.defaultProps = {
  as: "h1",
  variant: "heading"
};

export default Heading;
