import type { FC, ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as RouterProvider } from "react-router-dom";
import { AccountProvider } from "clients/account/context";
import GlobalProvider from "clients/globalState";
import ToaProvider from "toamea/provider/ToaProvider";
import ToastProvider from "toamea/provider/ToastProvider";
import theme, { fontDefinitions } from "toamea/theme";
import { themeGlobal } from "toamea/theme/theme";

const queryClient = new QueryClient();

const Providers: FC<{ children: ReactNode }> = ({ children }) => (
  <ToaProvider theme={theme} global={[fontDefinitions, themeGlobal]}>
    <QueryClientProvider client={queryClient}>
      <AccountProvider>
        <GlobalProvider>
          <ToastProvider>
            <RouterProvider>{children}</RouterProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </ToastProvider>
        </GlobalProvider>
      </AccountProvider>
    </QueryClientProvider>
  </ToaProvider>
);

export default Providers;
