import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
// import { fetchForgotPassword } from "clients/account/api";
import type { PasswordCodeRequest } from "clients/account/types";
import Box from "toamea/elements/Box";
import Button from "toamea/elements/Button";
import Error from "toamea/elements/Error";
import Flex from "toamea/elements/Flex";
import Form from "toamea/form/Form";
import Input from "toamea/form/Input";

const PasswordCodeForm = ({ token }: { token: string }) => {
  const [error, setError] = useState("");

  const PasswordCodeSchema = Yup.object().shape({
    email: Yup.string().required("Code Required").length(7, "Code invalid")
  });

  useEffect(() => {
    // ToDo: remove
    // eslint-disable-next-line no-console
    console.log("token check, valid or blocked token: ", token);
  }, [token]);

  const onSubmit = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _values: PasswordCodeRequest,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _actions: FormikHelpers<PasswordCodeRequest>
  ) => {
    setError("");

    // ToDo: Update to reset CODE REQUEST.
    // const { isError, exception } = await fetchForgotPassword(values);
    // if (isError) {
    //   if (exception && typeof exception?.message !== "string") {
    //     // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    //     const { Code } = exception.message;

    //     actions.setFieldError("email", Email as string);
    //   } else {
    //     if (exception) {
    //       // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    //       setError(exception.message);
    //     }
    //   }
    //   actions.setSubmitting(false);
    // } else {
    //   // Todo: decide what to do either recovery code screen or success message
    // }
    // console.log("Code Submitted: ", values);
  };

  return (
    <Formik
      initialValues={{
        code: ""
      }}
      validationSchema={PasswordCodeSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form
          sx={{
            width: 300,
            mx: "auto",
            display: "flex",
            flexDirection: "column",
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                "-webkit-appearance": "none",
                margin: 0
              },
            "input[type=number]": {
              "-moz-appearance": "textfield"
            }
          }}
          onKeyDown={(e): void => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        >
          <Input
            type="number"
            name="code"
            label="Enter Your Reset Code"
            autoComplete="off"
            spellCheck="false"
            autoCapitalize="off"
          />
          <Box sx={{ mt: 12 }}>
            {error && <Error>{error.toString()}</Error>}
            <Flex
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </Flex>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordCodeForm;
