import { api } from "../api";

export const fetchPermission = async () => {
  return api
    .get<boolean>("utility/permission")
    .then((res) => res.data)
    .catch(() => false);
};

export const fetchMode = async () => {
  return api.get<boolean>("utility").then((res) => res.data);
};
