import type { AxiosError } from "axios";
import type { UseQueryResult } from "react-query";
import { useQuery } from "react-query";
import { fetchMode, fetchPermission } from "../api";

type Options = {
  enabled?: boolean;
};

export const usePermission = (
  options?: Options
): UseQueryResult<boolean, AxiosError> => {
  return useQuery<boolean, AxiosError>(
    ["Permission"],
    () => fetchPermission(),
    options
  );
};

export const useMode = (): UseQueryResult<boolean, AxiosError> => {
  return useQuery<boolean, AxiosError>(["Mode"], () => fetchMode());
};
