export type GlobalStateType = {
  searchParameter: string;
};

export type GlobalContextType = {
  state: GlobalStateType;
  dispatch: React.Dispatch<ACTION_SET_SEARCH>;
};

// eslint-disable-next-line no-shadow
export enum Global_ACTION_TYPE {
  SEARCH_SET = "SET_SEARCH",
  SEARCH_CLEAR = "SEARCH_CLEAR"
}

type ACTION_SET_SEARCH = {
  type: Global_ACTION_TYPE.SEARCH_SET;
  payload: string;
};

type ACTION_CLEAR_SEARCH = {
  type: Global_ACTION_TYPE.SEARCH_CLEAR;
};

// prettier-ignore
export type GlobalActionProps = | ACTION_SET_SEARCH | ACTION_CLEAR_SEARCH;
