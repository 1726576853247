import { Formik } from "formik";
import { useContext } from "react";
import { Search as SearchIcon, XCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "clients/globalState";
import { Global_ACTION_TYPE } from "clients/globalState/types";
import Button from "toamea/elements/Button";
import Flex from "toamea/elements/Flex";
import BaseInput from "toamea/form/BaseInput";
import Form from "toamea/form/Form";

const Search = () => {
  const { dispatch } = useContext(GlobalContext);
  const navigation = useNavigate();

  return (
    <Formik
      initialValues={{
        searchParameter: ""
      }}
      onSubmit={(values): void => {
        if (values.searchParameter.trim() === "") {
          return;
        }

        dispatch({
          type: Global_ACTION_TYPE.SEARCH_SET,
          payload: values.searchParameter
        });
        navigation("search");
      }}
    >
      {({ setFieldValue, ...formikProps }) => (
        <Form
          sx={{
            width: ["100%", 500],
            mx: "auto",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Flex
            sx={{
              flexDirection: "row",
              alignItems: "center",
              border: "1px solid",
              borderRadius: "4px",
              pr: 1,
              color: "inherit"
            }}
          >
            <BaseInput
              sx={{
                border: "none",
                borderRadius: "none",
                color: "none"
              }}
              type="text"
              id={"searchParameter"}
              name={"searchParameter"}
              aria-label="Search"
              placeholder="Search"
              autoComplete="true"
              spellCheck="true"
              autoCapitalize="true"
            />
            <Button
              variant="secondary"
              sx={{
                p: 0,
                mr: 2,
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: "primary"
              }}
              onClick={(): void => {
                setFieldValue("searchParameter", "");
              }}
            >
              <XCircle aria-label="Clear" />
            </Button>
            <Button
              variant="secondary"
              sx={{
                p: 0,
                width: 28,
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: "primary"
              }}
              onClick={(): void => formikProps.handleSubmit()}
            >
              <SearchIcon aria-label="Search" />
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default Search;
