import ProfileImage from "../ProfileImage";
import logo from "./images/toa-games-logo-45x214.png";
import squareLogo from "./images/toa-games-sq-logo-45x45.png";
import Nav from "./Nav";
import { useAccount } from "clients/account/hook/useAccount";
import DarkToggle from "components/DarkToggle";
import Box from "toamea/elements/Box";
import Button from "toamea/elements/Button";
import Flex from "toamea/elements/Flex";
import Image from "toamea/elements/Image";
import Link from "toamea/elements/Link";
import Search from "toamea/form/Search";
import DropDown from "toamea/modules/DropDown";
import type { IsMaintenanceProps } from "~/Routes";

const Header = ({ isMaintenance = false }: IsMaintenanceProps) => {
  const { profileImage } = useAccount();

  return (
    <Flex
      sx={{
        p: 1,
        borderBottom: "gray 4px solid"
      }}
    >
      <Box sx={{ width: [45, 214], alignSelf: "center" }}>
        <Link sx={{ textDecoration: "none" }} to={"/"}>
          <Image
            alt="Toa Games Logo - Home"
            sx={{ display: ["none", "block"] }}
            src={logo}
          />
          <Image
            alt="Toa Games Logo - Home"
            sx={{ display: ["block", "none"] }}
            src={squareLogo}
          />
        </Link>
      </Box>
      <Flex sx={{ flex: "1 1 auto" }}>
        {!isMaintenance && (
          <Flex
            sx={{
              px: 2,
              justifyContent: "flex-end",
              flex: "1 1 auto",
              alignItems: "center"
            }}
          >
            <Search />
          </Flex>
        )}
        {isMaintenance && (
          <Flex
            sx={{
              px: 2,
              justifyContent: "flex-end",
              flex: "1 1 auto",
              alignItems: "center"
            }}
          >
            <DarkToggle isMaintenance={isMaintenance} />
          </Flex>
        )}
        {!isMaintenance && (
          <DropDown
            label="User Menu"
            buttonChild={
              <Button
                variant="secondary"
                className="ignore-onclickoutside"
                sx={{ p: 0, width: 45, border: "none" }}
              >
                <ProfileImage
                  profileImage={profileImage}
                  sx={{ width: 45, height: 45, border: "none" }}
                />
              </Button>
            }
          >
            <Nav />
          </DropDown>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
