import type { ErrorMessageProps } from "formik";
import { ErrorMessage as FormBase } from "formik";
import type { FC } from "react";
import { forwardRef } from "react";
import type { BoxProps } from "theme-ui";
import { Box } from "theme-ui";

interface IErrorMessage extends BoxProps, Omit<ErrorMessageProps, "children"> {
  name: string;
}

const ErrorMessage: FC<IErrorMessage> = forwardRef((props, ref) => (
  <Box
    ref={ref}
    {...props}
    sx={{
      color: "error",
      fontWeight: "bold",
      fontSize: 12,
      ...props.sx
    }}
  >
    <FormBase name={props.name} />
  </Box>
));

ErrorMessage.displayName = "ErrorMessage";

export default ErrorMessage;
