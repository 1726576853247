import type { FieldAttributes } from "formik";
import { Field } from "formik";
import type { FC } from "react";
import { forwardRef } from "react";
import type { InputProps } from "theme-ui";
import BaseInput from "toamea/elements/Input";

export interface BaseInputProps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extends FieldAttributes<any>,
    Omit<InputProps, "form"> {}

const Input: FC<BaseInputProps> = forwardRef((props, ref) => (
  <Field as={BaseInput} ref={ref} {...props} />
));

Input.displayName = "Input";

export default Input;
