import { Form as BaseForm } from "formik";
import type { FC } from "react";
import { forwardRef } from "react";
import type { BoxProps } from "theme-ui";
import { Box as Base } from "theme-ui";

const Form: FC<BoxProps> = forwardRef((props, ref) => (
  <Base as={BaseForm} ref={ref} {...props} />
));

Form.displayName = "Form";

export default Form;
