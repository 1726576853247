import type { FC } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount } from "clients/account/hook/useAccount";
import PasswordCodeForm from "components/forms/PasswordCode";
import PasswordRecoveryForm from "components/forms/PasswordRecoveryForm";
import Container from "toamea/elements/Container";
import Heading from "toamea/elements/Heading";

const PasswordRecovery: FC = () => {
  const navigation = useNavigate();
  const { isAuthenticated } = useAccount();
  const { token } = useParams();

  useEffect(() => {
    if (isAuthenticated) {
      if (
        window.history.state &&
        (window.history.state as { idx: number; key: string; usr: unknown })
          ?.idx > 0
      ) {
        navigation(-1);
      } else {
        navigation("/", { replace: true });
      }
    }
  }, [isAuthenticated, navigation]);

  return (
    <Container>
      <Heading sx={{ textAlign: "center" }}>Password Recovery</Heading>
      {token ? <PasswordCodeForm token={token} /> : <PasswordRecoveryForm />}
    </Container>
  );
};

export default PasswordRecovery;
