import { Global_ACTION_TYPE } from "./types";
import type { GlobalActionProps, GlobalStateType } from "./types";

export function globalContextReducer(
  state: GlobalStateType,
  action: GlobalActionProps
): GlobalStateType {
  switch (action.type) {
    case Global_ACTION_TYPE.SEARCH_SET: {
      return { ...state, searchParameter: action.payload };
    }
    case Global_ACTION_TYPE.SEARCH_CLEAR: {
      return { ...state, searchParameter: "" };
    }
    default: {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
      throw new Error(`Unhandled action type: ${(action as any).type}`);
    }
  }
}
