import { Outlet } from "react-router-dom";
import Footer from "components/Footer";
import Header from "components/Header";
import Box from "toamea/elements/Box";

type AppProps = {
  isMaintenance?: boolean;
};

function App({ isMaintenance = false }: AppProps) {
  return (
    <>
      <Header isMaintenance={isMaintenance} />
      <Box sx={{ minHeight: "100vh" }}>
        <Outlet />
      </Box>
      <Footer />
    </>
  );
}

export default App;
