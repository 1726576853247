import type { ThemeUIStyleObject, BoxProps as ThemeBoxProps } from "theme-ui";
import { Box } from "theme-ui";
import type { ForwardRef } from "~/types";

// Box.displayName = "Box";

// export default Box;

export const buttonTheme: Record<string, ThemeUIStyleObject> = {
  primary: {}
};

type Variant = keyof typeof buttonTheme;

export type BoxProps = Omit<ThemeBoxProps, "variant"> & {
  variant?: Variant;
};

Box.displayName = "Box";

Box.defaultProps = { variant: "primary" };

export default Box as ForwardRef<HTMLDivElement, BoxProps>;
