import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import Providers from "./Providers";
import reportWebVitals from "./reportWebVitals";
import Routes from "./Routes";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <Providers>
      <Routes />
    </Providers>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
