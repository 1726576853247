import { forwardRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import type { BoxProps } from "theme-ui";
import { useThemeUI } from "theme-ui";
import { useAccount } from "clients/account/hook/useAccount";
import { useUpdateAccount } from "clients/account/hook/useUpdateAccount";
import { useMode } from "clients/utilities/hooks/useUtilities";
import DarkToggle from "components/DarkToggle";
import Box from "toamea/elements/Box";
import Flex from "toamea/elements/Flex";
import Link from "toamea/elements/Link";
import { AVAILABLE_MODES } from "toamea/theme/theme";
import { LinkPaths } from "~/constants";

type NavProps = BoxProps & {
  toCloseDropdown?: () => void;
};

const Nav = forwardRef<HTMLElement, NavProps>(({ toCloseDropdown }, ref) => {
  const { isAuthenticated, firstName, isCreator } = useAccount();
  const { unsetAccount } = useUpdateAccount();
  const { addToast } = useToasts();
  const { data: mode } = useMode();
  const navigate = useNavigate();
  const { colorMode } = useThemeUI();

  const onLogout = useCallback(() => {
    unsetAccount();

    if (mode) {
      navigate(0);
    } else {
      addToast(`Goodbye, ${firstName}!`, { appearance: "success" });
    }
  }, [addToast, firstName, mode, navigate, unsetAccount]);

  return (
    <Flex
      ref={ref}
      sx={{
        mt: "3px",
        backgroundColor:
          colorMode === AVAILABLE_MODES.DARK ? "black" : "lightgray",
        border: "2px solid",
        borderColor: "primary",
        flexDirection: "column",
        minWidth: 200,
        position: "absolute",
        right: 0,
        "a:last-child": { border: "none" }
      }}
    >
      <Box sx={{ p: 12, borderBottom: "2px solid", borderColor: "primary" }}>
        Welcome {firstName}
      </Box>
      {isCreator ? (
        <Link
          onClick={toCloseDropdown}
          to={LinkPaths.CreatorDashboard}
          sx={{ p: 12, borderBottom: "2px solid", borderColor: "primary" }}
        >
          Creator Dashboard
        </Link>
      ) : (
        <Link
          onClick={toCloseDropdown}
          to={LinkPaths.BecomeACreator}
          sx={{ p: 12, borderBottom: "2px solid", borderColor: "primary" }}
        >
          Become a Creator
        </Link>
      )}
      {isAuthenticated ? (
        <Link
          onClick={toCloseDropdown}
          to={LinkPaths.Preferences}
          sx={{ p: 12, borderBottom: "2px solid", borderColor: "primary" }}
        >
          Preferences
        </Link>
      ) : (
        <>
          <Link
            onClick={toCloseDropdown}
            to={LinkPaths.Login}
            sx={{
              p: 12,
              borderBottom: "2px solid",
              borderColor: "primary"
            }}
          >
            Login
          </Link>
          <Link
            onClick={toCloseDropdown}
            to={LinkPaths.CreateAccount}
            sx={{
              p: 12,
              borderBottom: "2px solid",
              borderColor: "primary"
            }}
          >
            Create Account
          </Link>
        </>
      )}
      <DarkToggle />
      {isAuthenticated && (
        <Link
          onClick={onLogout}
          sx={{
            p: 12,
            borderBottom: "2px solid",
            borderTop: 0,
            borderRight: 0,
            borderLeft: 0,
            borderColor: "primary",
            backgroundColor: "unset",
            textAlign: "left"
          }}
        >
          Logout
        </Link>
      )}
    </Flex>
  );
});

export default Nav;
