import type { AccountData } from "../types";
import { removeAccount, setAccount, updateAccount } from "../utilities";
import type { AccountActionsTypes } from "./Action";
import { AccountActions } from "./Action";
import { initialState } from "./initialState";

export const AccountReducer = (
  state: AccountData,
  action: AccountActionsTypes
): AccountData => {
  switch (action.type) {
    case AccountActions.SET_ACCOUNT:
      setAccount({ ...action.payload, isAuthenticated: true });
      return { ...state, ...action.payload, isAuthenticated: true };
    case AccountActions.UPDATE_ACCOUNT:
      updateAccount(action.payload);
      return { ...state, ...action.payload };
    case AccountActions.UNSET_ACCOUNT:
      removeAccount();
      return { ...initialState };
    default:
      throw new Error("Unsupported Action");
  }
};
