import type {
  InputProps as ThemeInputProps,
  ThemeUIStyleObject
} from "theme-ui";
import { Input } from "theme-ui";
import type { ForwardRef } from "~/types";

const autoFillStyle: ThemeUIStyleObject = {
  ":-internal-autofill-selected": {
    backgroundImage: "none !important",
    backgroundColor: "background !important",
    color: "text !important",
    boxShadow: "none"
  },
  ":focus": {
    boxShadow: "none !important"
  }
};

export const inputTheme: Record<string, ThemeUIStyleObject> = {
  primary: {
    fontFamily: "body",
    color: "white",
    fontWeight: "600",
    fontSize: "16px",
    textDecoration: "none",
    borderRadius: "8px",
    backgroundColor: "primary",
    px: 3,
    py: 2,
    cursor: "pointer",
    ":hover": { backgroundColor: "primaryHover" },
    autoFillStyle
  },
  secondary: {
    fontFamily: "body",
    color: "primary",
    fontWeight: "600",
    fontSize: "16px",
    textDecoration: "none",
    borderRadius: "none",
    backgroundColor: "unset",
    px: 3,
    py: 2,
    cursor: "pointer",
    autoFillStyle
  },
  small: {
    fontFamily: "body",
    color: "white",
    fontWeight: "400",
    fontSize: "12px",
    textDecoration: "none",
    borderRadius: "8px",
    backgroundColor: "primary",
    px: 2,
    py: 1,
    cursor: "pointer",
    ":hover": { backgroundColor: "primaryHover" },
    autoFillStyle
  },
  link: {
    fontFamily: "body",
    color: "primary",
    fontWeight: "bold",
    fontSize: "16px",
    textDecoration: "none",
    backgroundColor: "unset",
    px: 3,
    py: 2,
    cursor: "pointer",
    ":hover": { backgroundColor: "lightGray" },
    autoFillStyle
  },
  icon: {
    fontFamily: "body",
    color: "primary",
    fontWeight: "bold",
    fontSize: "16px",
    textDecoration: "none",
    backgroundColor: "unset",
    cursor: "pointer"
  }
};

type Variant = keyof typeof inputTheme;

export type InputProps = Omit<ThemeInputProps, "variant"> & {
  variant?: Variant;
};

Input.displayName = "Input";

Input.defaultProps = { variant: "primary" };

export default Input as ForwardRef<HTMLInputElement, InputProps>;
