import { useContext } from "react";
import type { AccountContextProps } from "../context";
import { AccountActions, AccountContext } from "../context";
import type { AccountData } from "../types";

type UseUpdateAccountProps = {
  updateAccount: (accountData: Partial<AccountData>) => void;
  setAccount: (accountData: AccountData) => void;
  unsetAccount: () => void;
};

export const useUpdateAccount = (): UseUpdateAccountProps => {
  const context = useContext(AccountContext) as AccountContextProps;

  if (!context) {
    throw new Error("useUpdateAccount must be used within an AccountProvider");
  }

  const { dispatch } = context;

  const setAccount = (accountData: AccountData): void => {
    dispatch({ type: AccountActions.SET_ACCOUNT, payload: accountData });
  };

  const updateAccount = (accountData: Partial<AccountData>): void => {
    dispatch({ type: AccountActions.UPDATE_ACCOUNT, payload: accountData });
  };

  const unsetAccount = (): void => {
    dispatch({ type: AccountActions.UNSET_ACCOUNT });
  };

  return { updateAccount, unsetAccount, setAccount };
};
