import type { FC } from "react";
import { useEffect, useState, forwardRef } from "react";
import type { ImageProps as ThemeImageProps } from "theme-ui/";
import { Image as Base } from "theme-ui/";

type ImageProps = ThemeImageProps & {
  fallbackSrc?: string;
};

const ThemeImage: FC<ImageProps> = forwardRef(
  ({ src: initialSrc, fallbackSrc, alt, ...props }, ref) => {
    const [src, setSrc] = useState(initialSrc);
    useEffect(() => {
      if (!src && !fallbackSrc) {
        return setSrc(undefined);
      }
      if (!src && fallbackSrc) {
        return setSrc(fallbackSrc);
      }
      if (src && !src.includes("http")) {
        return setSrc(src);
      }

      const image = new Image();
      image.src = src || "";
      image.onload = (): void => {
        setSrc(src);
      };
      image.onerror = (): void => {
        setSrc(fallbackSrc);
      };
    }, [src, fallbackSrc]);

    return <Base alt={alt} src={src} ref={ref} {...props} />;
  }
);

ThemeImage.displayName = "Image";

export default ThemeImage;
