/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { api } from "../api";
import type {
  ForgotPasswordRequest,
  LoginRequest,
  RegisterCreatorRequest,
  RegisterRequest,
  UpdateAccountRequest
} from "./types";

export const fetchLogin = async (parameters: LoginRequest) => {
  return api
    .post(
      "account/login",
      { ...parameters },
      { headers: { "Content-Type": "application/json" } }
    )
    .then((res) => ({ data: res.data, isError: false, exception: undefined }))
    .catch((error) => {
      return {
        data: undefined,
        isError: true,
        exception: {
          status: error?.response?.status,
          message: error?.response?.data
        }
      };
    });
};

export const fetchForgotPassword = async (
  parameters: ForgotPasswordRequest
) => {
  return api
    .post(
      "account/forgot-password",
      { ...parameters },
      { headers: { "Content-Type": "application/json" } }
    )
    .then((res) => ({ data: res.data, isError: false, exception: undefined }))
    .catch((error) => {
      return {
        data: undefined,
        isError: true,
        exception: {
          status: error?.response?.status,
          message: error?.response?.data
        }
      };
    });
};

export const fetchRegister = async (parameters: RegisterRequest) => {
  return api
    .post(
      "account/register",
      { ...parameters },
      { headers: { "Content-Type": "application/json" } }
    )
    .then((res) => ({ data: res.data, isError: false, exception: undefined }))
    .catch((error) => ({
      data: undefined,
      isError: true,
      exception: {
        status: error.response.status,
        message: error.response.data
      }
    }));
};

export const fetchAccount = async () => {
  return api
    .post("account", {}, { headers: { "Content-Type": "application/json" } })
    .then((res) => res.data);
};

export const updateAccountRequest = async ({
  firstName,
  lastName,
  screenName,
  profileImage,
  password,
  currentPassword,
  confirmPassword,
  allowAdult
}: UpdateAccountRequest) => {
  const formData = new FormData();
  if (firstName) {
    formData.append("firstName", firstName);
  }
  if (lastName) {
    formData.append("lastName", lastName);
  }
  if (screenName) {
    formData.append("screenName", screenName);
  }
  if (profileImage) {
    formData.append("profileImage", profileImage);
  }
  if (password) {
    formData.append("password", password);
  }
  if (currentPassword) {
    formData.append("currentPassword", currentPassword);
  }
  if (confirmPassword) {
    formData.append("confirmPassword", confirmPassword);
  }
  if (
    allowAdult === true ||
    allowAdult === false
    // Was this needed (allowAdult.toString() === "true" || allowAdult.toString() === "false")
  ) {
    formData.append("allowAdult", allowAdult.toString());
  }
  return api
    .put("account/update", formData, {
      headers: { "content-type": "multipart/form-data" }
    })
    .then((res) => ({ data: res.data, isError: false, exception: undefined }))
    .catch((error) => ({
      data: undefined,
      isError: true,
      exception: {
        status: error.response.status,
        message: error.response.data
      }
    }));
};

export const fetchRegisterCreator = async (
  parameters: RegisterCreatorRequest
) => {
  return api
    .post(
      "creator/register",
      { ...parameters },
      { headers: { "Content-Type": "application/json" } }
    )
    .then((res) => ({ data: res.data, isError: false, exception: undefined }))
    .catch((error) => ({
      data: undefined,
      isError: true,
      exception: {
        status: error.response.status,
        message: error.response.data
      }
    }));
};
