import { initialState } from "../context/initialState";
import type { AccountData } from "../types";

const isBrowser = (): boolean =>
  // eslint-disable-next-line no-implicit-coercion
  !!(typeof window !== "undefined" && window.localStorage);

export const KEY = "toa/account";

export const setAccount = (account: AccountData): void => {
  if (isBrowser()) {
    const stringAccount = JSON.stringify(account);
    window.localStorage.setItem(KEY, stringAccount);
  }
};

export const getAccount = (): AccountData | "" | null => {
  if (isBrowser()) {
    const accountJson = window.localStorage.getItem(KEY);

    return accountJson && (JSON.parse(accountJson) as AccountData);
  }

  return initialState;
};

export const updateAccount = (newProps: Partial<AccountData>): void => {
  if (isBrowser()) {
    const accountJson = getAccount();
    const newAccountData = { ...accountJson, ...newProps } as AccountData;
    setAccount(newAccountData);
  }
};

export const removeAccount = (): void => {
  if (isBrowser()) {
    window.localStorage.removeItem(KEY);
  }
};

export const initializeAccount = (): void => {
  if (isBrowser()) {
    if (!window.localStorage.getItem(KEY)) {
      setAccount(initialState);
    }
  }
};
