import axios from "axios";
import type { AccountData } from "clients/account/types";
import { getAccount } from "clients/account/utilities";

// ToDo set url in setting file.
export const api = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
    "Cache-Control": "no-cache, max-age=0",
    "Access-Control-Allow-Origin": "*"
  }
});

api.interceptors.request.use((config) => {
  const data = getAccount() as AccountData | null;
  if (data && typeof data?.token === "string" && config && config.headers) {
    if (data.token.length > 3) {
      config.headers.Authorization = `Bearer ${data.token}`;
    }
  }

  return config;
});

// ToDo: add global logging here
// api.interceptors.response.use(response => {
//   console.log('API RESPONSE: ', response)
//   return response;
// }, ( error ) => {
//   console.log('API ERROR: ', error.response.status);
//   return ({
//     status: error.response.status,
//     message: error.response.data
//   });
// });

interface ISetApiDefaults {
  baseURL: string;
  locale?: string;
}

export const setApiDefaults = ({
  baseURL,
  locale = "en-US"
}: ISetApiDefaults): void => {
  axios.defaults.baseURL = baseURL;
  axios.defaults.headers.common["Accept-Language"] = locale;
};
