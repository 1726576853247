import type { FunctionComponent, LazyExoticComponent, ReactNode } from "react";
import { lazy } from "react";
import type {
  IndexRouteProps,
  LayoutRouteProps,
  PathRouteProps
} from "react-router";
import App from "./App";
import PasswordRecovery from "./pages/PasswordRecovery";
import ProtectedRoute from "./utilities/ProtectedRoute";

type ExtRouteProps = {
  title: string | "";
  path?: string;
  index?: boolean;
  caseSensitive?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component?: FunctionComponent<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LazyComponent?: LazyExoticComponent<any>;
  children?: ReactNode;
  routes?: RouteProperties[] | [];
};

type ExtPathRouteProps = Omit<PathRouteProps, "element" | "title"> &
  ExtRouteProps;
type ExtLayoutRouteProps = Omit<LayoutRouteProps, "element" | "title"> &
  ExtRouteProps;
type ExtIndexRouteProps = Omit<IndexRouteProps, "element" | "title"> &
  ExtRouteProps;

export type RouteProperties =
  | ExtPathRouteProps
  | ExtLayoutRouteProps
  | ExtIndexRouteProps;

export const routes: RouteProperties[] = [
  {
    title: "",
    path: "/",
    Component: App,
    routes: [
      {
        title: "Search Toa Games",
        path: "search",
        LazyComponent: lazy(() => import("./pages/Search"))
      },
      {
        title: "User Preferences",
        path: "preferences",
        LazyComponent: lazy(() => import("./pages/account/Preferences"))
      },
      {
        title: "User Account",
        path: "account",
        Component: ProtectedRoute,
        routes: [
          {
            title: "Preferences",
            path: "preferences",
            LazyComponent: lazy(() => import("./pages/account/Preferences"))
          }
        ]
      },
      {
        title: "Password Recovery",
        path: "password-recovery",
        Component: PasswordRecovery
      },
      {
        title: ":name",
        path: "game/:name",
        LazyComponent: lazy(() => import("./pages/Game"))
      },
      {
        title: "Become A Creator",
        path: "become-a-creator",
        LazyComponent: lazy(() => import("./pages/BecomeACreator"))
      },
      {
        title: "Login to Toa Games",
        path: "login",
        LazyComponent: lazy(() => import("./pages/Login"))
      },
      {
        title: "Password Recovery for Toa Games",
        path: "password-recovery/:token",
        LazyComponent: lazy(() => import("./pages/PasswordRecovery"))
      },
      {
        title: "Logout",
        path: "logout",
        LazyComponent: lazy(() => import("./pages/Logout"))
      },
      {
        title: "Create Account",
        path: "create-account",
        LazyComponent: lazy(() => import("./pages/CreateAccount"))
      },
      {
        title: "Creator",
        path: "creator",
        LazyComponent: lazy(() => import("./pages/creator/Creators")),
        routes: [
          {
            title: "Creator Sign Up",
            path: "sign-up",
            LazyComponent: lazy(() => import("./pages/creator/SignUp"))
          },
          {
            title: ":creatorSafeName",
            path: ":creatorSafeName",
            // ToDo: Should this be the same as Creator main route component?
            LazyComponent: lazy(() => import("./pages/creator/Creator"))
          },
          {
            title: ":creatorSafeName Dashboard",
            path: "dashboard",
            Component: ProtectedRoute,
            routes: [
              {
                title: ":subPage > :game > :gameEdit Edit Game", // ToDo: Check this name
                path: ":subPage?/:game?/:gameEdit?",
                LazyComponent: lazy(() => import("./pages/creator/Dashboard"))
              }
            ]
          }
        ]
      },
      {
        title: ":creatorSafeName Creator Page", // ToDo: Make work with Creator or Game.
        path: ":creatorSafeName",
        LazyComponent: lazy(() => import("./pages/creator/Creator"))
      },
      {
        title: "Toa Games",
        index: true,
        LazyComponent: lazy(() => import("./pages/Home"))
      }
    ]
  }
];
export const maintenanceRoutes: RouteProperties[] = [
  {
    title: "Great Things To Come",
    path: "/",
    Component: App,
    routes: [
      {
        title: "Great Things To Come",
        path: "access",
        LazyComponent: lazy(() => import("./pages/Login"))
      },
      {
        title: "Great Things To Come",
        path: "logout",
        LazyComponent: lazy(() => import("./pages/Logout"))
      },
      {
        title: "Great Things To Come",
        index: true,
        LazyComponent: lazy(() => import("./pages/GreatThingsComing"))
      },
      {
        path: "*",
        title: "Great Things To Come",
        index: true,
        LazyComponent: lazy(() => import("./pages/GreatThingsComing"))
      }
    ]
  }
];
