import type {
  ButtonProps as ThemeButtonProps,
  ThemeUIStyleObject
} from "theme-ui";
import { Button } from "theme-ui";
import type { ForwardRef } from "~/types";

export const buttonTheme: Record<string, ThemeUIStyleObject> = {
  primary: {
    fontFamily: "body",
    color: "white",
    fontWeight: "600",
    fontSize: "16px",
    textDecoration: "none",
    borderRadius: "8px",
    backgroundColor: "primary",
    px: 3,
    py: 2,
    cursor: "pointer",
    ":hover": { backgroundColor: "primaryHover" }
  },
  secondary: {
    fontFamily: "body",
    color: "primary",
    fontWeight: "600",
    fontSize: "16px",
    textDecoration: "none",
    borderRadius: "none",
    backgroundColor: "unset",
    px: 3,
    py: 2,
    cursor: "pointer"
  },
  small: {
    fontFamily: "body",
    color: "white",
    fontWeight: "400",
    fontSize: "12px",
    textDecoration: "none",
    borderRadius: "8px",
    backgroundColor: "primary",
    px: 2,
    py: 1,
    cursor: "pointer",
    ":hover": { backgroundColor: "primaryHover" }
  },
  link: {
    fontFamily: "body",
    color: "primary",
    fontWeight: "bold",
    fontSize: "16px",
    textDecoration: "none",
    backgroundColor: "unset",
    px: 3,
    py: 2,
    cursor: "pointer",
    ":hover": { backgroundColor: "lightGray" }
  },
  icon: {
    fontFamily: "body",
    color: "primary",
    fontWeight: "bold",
    fontSize: "16px",
    textDecoration: "none",
    backgroundColor: "unset",
    cursor: "pointer"
  }
};

type Variant = keyof typeof buttonTheme;

export type ButtonProps = Omit<ThemeButtonProps, "variant"> & {
  variant?: Variant;
};

Button.displayName = "Button";

Button.defaultProps = { variant: "primary", type: "button" };

export default Button as ForwardRef<HTMLButtonElement, ButtonProps>;
