import { Global } from "@emotion/react";
import type { FC, ReactNode } from "react";
import type { Theme } from "theme-ui";
import { ThemeProvider } from "theme-ui";

interface IToaThemeProvider {
  theme: Theme;
  global?: string[];
  children: ReactNode;
}

const ToaThemeProvider: FC<IToaThemeProvider> = ({
  theme,
  global,
  children
}) => {
  return (
    <ThemeProvider theme={theme}>
      {}
      {global &&
        global.map((styles, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Global key={`global-styles-${i}`} styles={styles} />
        ))}
      {children}
    </ThemeProvider>
  );
};

export default ToaThemeProvider;
