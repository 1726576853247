import type { FC } from "react";
import { forwardRef } from "react";
import type { BoxProps } from "theme-ui";
import { Box as Base } from "theme-ui";

const Box: FC<BoxProps> = forwardRef((props, ref) => (
  <Base
    ref={ref}
    {...props}
    sx={{
      fontWeight: "bold",
      fontSize: 16,
      color: "error",
      ...props.sx
    }}
  />
));

Box.displayName = "Error";

export default Box;
