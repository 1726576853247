import type { NavigateProps } from "react-router";
import { Outlet } from "react-router";
import { Navigate } from "react-router-dom";
import { useAccount } from "clients/account/hook/useAccount";

type ProtectedRouteProps = Partial<NavigateProps> & {
  to?: string;
};

const ProtectedRoute = ({ to = "/login", ...props }: ProtectedRouteProps) => {
  const { isAuthenticated } = useAccount();

  if (!isAuthenticated) {
    return <Navigate replace={true} {...props} to={to} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
