import { useThemeUI } from "theme-ui";
import Box from "toamea/elements/Box";
import { linkTheme } from "toamea/elements/Link";
import Switch from "toamea/elements/Switch";
import { AVAILABLE_MODES } from "toamea/theme/theme";
import type { IsMaintenanceProps } from "~/Routes";

const DarkToggle = ({ isMaintenance = false }: IsMaintenanceProps) => {
  const { setColorMode, colorMode } = useThemeUI();

  return (
    <Box
      sx={{
        ...linkTheme.primary,
        p: 12,
        borderBottom: isMaintenance ? 0 : "2px solid",
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
        borderColor: "primary",
        backgroundColor: "unset",
        textAlign: "left",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        minWidth: "180px"
      }}
    >
      {"Dark Mode"}
      <Switch
        onChange={(): void => {
          if (setColorMode) {
            const newColorMode =
              colorMode === AVAILABLE_MODES.DARK
                ? AVAILABLE_MODES.DEFAULT
                : AVAILABLE_MODES.DARK;

            // updateAccount({ colorMode: newColorMode });
            setColorMode(newColorMode);
          }
        }}
        checked={colorMode === AVAILABLE_MODES.DARK ? true : false}
      />
    </Box>
  );
};

export default DarkToggle;
