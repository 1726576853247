import type { FC } from "react";
import { useCallback, useState } from "react";
import { Eye, EyeOff } from "react-feather";
import type { BoxProps, ThemeUIStyleObject } from "theme-ui";
import ErrorMessage from "../ErrorMessage";
import Label from "../Label";
import Box from "toamea/elements/Box";
import Button from "toamea/elements/Button";
import Flex from "toamea/elements/Flex";
import type { BaseInputProps } from "toamea/form/BaseInput";
import BaseInput from "toamea/form/BaseInput";

type Booleanish = boolean | "true" | "false";

export type InputProps = BoxProps &
  BaseInputProps & {
    name: string;
    label?: string;
    type?: string;
    required?: boolean;
    autoComplete?: string;
    spellCheck?: Booleanish;
    autoCapitalize?: string;
    autofillBackgroundColor?: string;
    autofillColor?: string;
    disabled?: boolean;
    sx?: ThemeUIStyleObject;
    baseInputSx?: ThemeUIStyleObject;
    labelAsPlaceholder?: boolean;
  };

const Input: FC<InputProps> = ({
  name,
  type: setType = "text",
  label,
  required = false,
  autoComplete: setAutoComplete = "on",
  spellCheck: setSpellCheck = "true" as Booleanish,
  autoCapitalize: setAutoCapitalize = "on",
  autofillBackgroundColor,
  autofillColor,
  disabled = false,
  labelAsPlaceholder = false,
  baseInputSx,
  sx
}) => {
  const [toggleType, setToggleType] = useState<string | undefined>(setType);

  const onToggle = useCallback(() => {
    setToggleType(!toggleType ? "text" : undefined);
  }, [toggleType]);

  const { autoComplete, spellCheck, autoCapitalize } =
    setType === "password"
      ? {
          autoComplete: "off",
          spellCheck: "false",
          autoCapitalize: "off"
        }
      : {
          autoComplete: setAutoComplete,
          spellCheck: setSpellCheck,
          autoCapitalize: setAutoCapitalize
        };

  return (
    <Box sx={{ py: 2, ...sx }}>
      {!labelAsPlaceholder && label && (
        <Label id={`${name}-input-label`} sx={{ mb: 1 }} htmlFor={name}>
          {label}
        </Label>
      )}
      <Flex>
        <BaseInput
          sx={baseInputSx}
          type={toggleType || setType}
          id={name}
          name={name}
          aria-label={label}
          aria-describedby={`${name}-input-message`}
          required={required}
          placeholder={labelAsPlaceholder ? label : undefined}
          autoComplete={autoComplete}
          autofillBackgroundColor={autofillBackgroundColor || "none"}
          autofillColor={autofillColor || "none"}
          spellCheck={spellCheck.toString() as Booleanish}
          autoCapitalize={autoCapitalize}
          disabled={disabled}
        />
        {setType === "password" && (
          <Button
            variant="secondary"
            id="password-toggle"
            sx={{
              width: 36,
              height: 36,
              p: 0,
              alignItems: "center",
              justifyContent: "center",
              ml: "-36px",
              cursor: "pointer",
              svg: {
                m: "auto"
              }
            }}
            aria-label={
              toggleType === "text" ? "Hide Password" : "Show Password"
            }
            onClick={onToggle}
          >
            {toggleType === "text" ? (
              <EyeOff role="img" aria-labelledby="password-toggle" />
            ) : (
              <Eye role="img" aria-labelledby="password-toggle" />
            )}
          </Button>
        )}
      </Flex>
      <ErrorMessage
        role="alert"
        id={`${name}-input-message`}
        sx={{ my: 1 }}
        name={name}
      />
    </Box>
  );
};
export default Input;
