import type { FC } from "react";
import { Spinner } from "theme-ui";
import Flex from "toamea/elements/Flex";

const Loading: FC = () => {
  return (
    <Flex sx={{ width: "100%", minHeight: 200, justifyContent: "center" }}>
      <Spinner sx={{ mt: 100 }} />
    </Flex>
  );
};

export default Loading;
