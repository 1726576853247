import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { fetchForgotPassword } from "clients/account/api";
import type { ForgotPasswordRequest } from "clients/account/types";
import Box from "toamea/elements/Box";
import Button from "toamea/elements/Button";
import Error from "toamea/elements/Error";
import Flex from "toamea/elements/Flex";
import Form from "toamea/form/Form";
import Input from "toamea/form/Input";

const PasswordRecoveryForm = () => {
  const [error, setError] = useState("");

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email Required")
      .email("Email must be a valid")
  });

  const onSubmit = async (
    values: ForgotPasswordRequest,
    actions: FormikHelpers<ForgotPasswordRequest>
  ): Promise<void> => {
    setError("");

    const { isError, exception } = await fetchForgotPassword(values);
    if (isError) {
      if (exception && typeof exception?.message !== "string") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { Email } = exception.message;

        actions.setFieldError("email", Email as string);
      } else {
        if (exception) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setError(exception.message);
        }
      }
      actions.setSubmitting(false);
    } else {
      // Todo: decide what to do either recovery code screen or success message
    }
  };

  return (
    <Formik
      initialValues={{
        email: ""
      }}
      validationSchema={LoginSchema}
      onSubmit={onSubmit}
    >
      {}
      {({ isSubmitting }) => (
        <Form
          sx={{
            width: 300,
            mx: "auto",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Input
            type="email"
            name="email"
            label="Your Email"
            className="username"
          />
          <Box sx={{ mt: 12 }}>
            {error && <Error>{error.toString()}</Error>}
            <Flex
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Button
                type="submit"
                // sx={{ mt: 1, cursor: "pointer" }}
                disabled={isSubmitting}
              >
                Email me recovery code
              </Button>
            </Flex>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordRecoveryForm;
