import type { FieldAttributes } from "formik";
import { Field } from "formik";
import type { FC } from "react";
import { forwardRef } from "react";
import type { SliderProps, ThemeUIStyleObject } from "theme-ui";
import { Slider as Base } from "theme-ui";

export const sliderTheme: Record<string, ThemeUIStyleObject> = {
  default: {
    color: "primary",
    background: "lightGray"
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IInputProps extends FieldAttributes<any>, SliderProps {}

const Slider: FC<IInputProps> = forwardRef((props, ref) => (
  <Field as={Base} ref={ref} {...props} />
));

Slider.displayName = "Slider";

Slider.defaultProps = { variant: "default" };

export default Slider;
