import type { FC } from "react";
import type { ReactSwitchProps } from "react-switch";
import ReactSwitch from "react-switch";
import { useThemeUI } from "theme-ui";

type Omit<T, U> = Pick<T, Exclude<keyof T, U>>;

type htmlInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type excludedHTMLInputProps =
  | "onFocus"
  | "onBlur"
  | "onKeyUp"
  | "onChange"
  | "ref"
  | keyof ReactSwitchProps;

type allowedHTMLinputProps = Omit<htmlInputProps, excludedHTMLInputProps>;

export type SwitchProps = ReactSwitchProps & allowedHTMLinputProps;

const Switch: FC<SwitchProps> = ({ offColor, onColor, ...remainderProps }) => {
  const {
    theme: { rawColors }
  } = useThemeUI();

  const unCheckedColor = (offColor ||
    rawColors?.contrastGray ||
    "#513100") as unknown as string;
  const checkedColor = (onColor ||
    rawColors?.primary ||
    "#ff9900") as unknown as string;

  return (
    <ReactSwitch
      {...remainderProps}
      offColor={unCheckedColor}
      onColor={checkedColor}
    />
  );
};

export default Switch;
