import type { ComponentType, FC } from "react";
import type { ToastProps, ToastProviderProps } from "react-toast-notifications";
import {
  DefaultToastContainer,
  ToastProvider as ReactToastProvider
} from "react-toast-notifications";
import Warning from "toamea/modules/Warning";

const ToastProvider: FC<ToastProviderProps> = ({
  autoDismissTimeout = 3000,
  autoDismiss = true,
  components,
  newestOnTop = true,
  placement = "top-center",
  portalTargetSelector,
  transitionDuration,
  children
}) => (
  <ReactToastProvider
    autoDismissTimeout={autoDismissTimeout}
    autoDismiss={autoDismiss}
    components={
      components || {
        ToastContainer: DefaultToastContainer,
        Toast: Warning as ComponentType<ToastProps>
      }
    }
    newestOnTop={newestOnTop}
    placement={placement}
    portalTargetSelector={portalTargetSelector}
    transitionDuration={transitionDuration}
  >
    {children}
  </ReactToastProvider>
);
export default ToastProvider;
