import type { FC } from "react";
import { SocialIcon } from "react-social-icons";
import { useThemeUI } from "theme-ui";
import Box from "toamea/elements/Box";
import Flex from "toamea/elements/Flex";

export const socials = [
  "https://twitter.com/games_toa",
  "https://www.youtube.com/channel/UCZ_ocDwK0Jo5M_irfiux7-w",
  "https://www.facebook.com/ToaGames-105958731749472"
];

const Footer: FC = () => {
  const { theme } = useThemeUI();

  return (
    <Flex
      sx={{
        mt: 3,
        pt: 1,
        pb: 3,
        px: [1, 3],
        borderTop: "gray 4px solid",
        flexDirection: "row",
        justifyContent: "space-between"
      }}
    >
      <Box
        sx={{
          fontSize: "x-small",
          textAlign: "left",
          my: 2,
          pl: 1,
          flex: "1 1 auto"
        }}
      >
        Copyright © {new Date().getFullYear()} ToaGames · Terms | Cookies |
        Privacy
      </Box>

      <Flex
        sx={{
          maxWidth: "300px",
          mx: "auto",
          justifyContent: "space-evenly"
        }}
      >
        {socials.map((url) => (
          <SocialIcon
            key={url}
            bgColor={(theme.colors?.primary as string) || ""} // "#074E54"
            style={{ height: 32, width: 32, margin: 4 }}
            url={url}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default Footer;
