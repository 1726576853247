import { createContext, useReducer } from "react";
import type { ReactElement } from "react";
import { defaultState } from "./defaultState";
import { globalContextReducer } from "./globalContextReducer";
import type { GlobalContextType } from "./types";

export const GlobalContext = createContext<GlobalContextType>({
  state: defaultState,
  dispatch: () => undefined
});

// To use Global State call bellow.
// const { state, dispatch } = useContext(GlobalContext);

const GlobalProvider = ({
  children
}: {
  children: ReactElement;
}): ReactElement => {
  const [state, dispatch] = useReducer(globalContextReducer, defaultState);
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export default GlobalProvider;
