import type { FC } from "react";
import { forwardRef } from "react";
import type { LabelProps } from "theme-ui";
import { Label as Base } from "theme-ui";

const Label: FC<LabelProps> = forwardRef((props, ref) => (
  <Base ref={ref} {...props} />
));

Label.displayName = "Label";

export default Label;
